.creditCardTotalsTable {
    margin-bottom: 15px;

    .headerRow {
        .headerCell {
            border-color: #888 !important;

            &[keyid='cardName'], &[keyid='startDate'], &[keyid='endDate'] {
                background-color: #f9da78;
            }

            &[keyid='adjustedTotal'], &[keyid='purchaseCount'], &[keyid='adjustedUpToToday'], &[keyid='progress'], &[keyid='projected'] {
                background-color: #85b86f;
            }
        }
    }

    .tableRow {
        .tableCell {
            border-color: #aaa !important;

            &[keyid='cardName'], &[keyid='startDate'], &[keyid='endDate'] {
                background-color: #fdf2d0;
            }

            &[keyid='adjustedTotal'], &[keyid='purchaseCount'], &[keyid='adjustedUpToToday'], &[keyid='progress'], &[keyid='projected'] {
                background-color: #d5e7cd;
            }
        }

        &.footerRow {
            .tableCell {
                background-color: #bcd6ac !important;

                &.undefinedCell {
                    background-color: transparent !important;
                    border: none !important;
                }
            }
        }
    }
}

.creditCardSumsTable {
    margin-top: 15px;

    .headerRow {
        .headerCell {
            background-color: #85b86f;
            border-color: #888 !important;

            &:nth-child(3) {
                background-color: #789de5;
            }
        }
    }

    .tableRow {
        .tableCell {
            background-color: #d5e7cd;
            border-color: #aaa !important;

            &:nth-child(3) {
                background-color: #a6c4e4;
            }

            &:nth-child(5) {
                background-color: #bcd6ac;
            }
        }
    }
}
