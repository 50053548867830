.purchasesContainer {
    background-color: #dcdcdc;
    display: grid;
    grid-template-rows: 100%;
    // margin-bottom: 100px;

    .layoutL &, .layoutXL & {
        grid-template-rows: 21px calc(100% - 21px);
    }

    .tableRow {
        grid-template-columns: 130px auto 175px 75px 75px 110px 110px 30px 0px;

        &:last-child {
            margin-bottom: 50px;
        }

        .tableCell {
            background-color: #fff;

            &.hasVenmo {
                cursor: pointer;
                position: relative;
                overflow: hidden;

                &:after {
                    background-color: #0096FF;
                    content: '';
                    position: absolute;
                    top: -5px;
                    right: -5px;
                    transform: rotate(45deg);
                    height: 10px;
                    width: 10px;
                }
            }

            .darkMode & {
                background-color: rgba(0, 0, 0, 0.4);
            }
        }

        .headerCell {
            background-color: #ff0;

            .darkMode & {
                background-color: rgba(0, 0, 0, 0.4);
            }
        }
    }
}
