.editCreditCardContainer {
    height: 100%;
    position: relative;

    .submitButton {
        bottom: 6px;
        margin: 0px;
        position: absolute;
        width: 100%;
    }
}