.monthTotalsContainer {

    .monthTotals {
        margin-top: 10px;

        &.smallLayout {
            display: block;
            margin-top: 0px;
        }

        .purchaseTableDragHandle {
            cursor: ew-resize;
        }
    }

    .monthTotals {
        .tableRow.highlighted .tableCell {
            background-color: #96ceff;
            /* border-color: #676767 !important; */
        }
    }
}