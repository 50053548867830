.spendingTables {

    .spendingToolBar {
        // background-color: #ededed;
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 8px;
        position: relative;

        display: grid;
        // grid-template-columns: 40% 20% 40%;
        grid-template-columns: 50px 1fr 625px 0px;
        grid-gap: 10px;

        .layoutM & {
            grid-template-columns: 50px 1fr 550px 0px;
        }
        .layoutS &, .layoutXS & {
            grid-template-columns: 50px 1fr;
        }

        .currentMonthBar {
            text-align: center;

            span {
                cursor: pointer;
                margin: 0px 5px;
                padding: 0px 5px;
            }
        }

        .newEntryButton {
            background-color: #ccc;
            border-radius: 5px;
            cursor: pointer;
            font-size: 26px;
            font-weight: 200;
            height: 24px;
            // left: 0px;
            line-height: 18px;
            // position: absolute;
            text-align: center;
            // top: 5px;
            width: 50px;

            &:hover {
                background-color: #aaa;
            }
        }

        .monthInfoDisplay {
            display: grid;
            font-size: 14px;
            grid-gap: 5px;
            grid-template-columns: 105px 95px 1fr 105px;
            padding: 3px 0px;
            grid-column: span 2;

            .layoutS &, .layoutXS & {
                grid-template-columns: 105px 1fr 105px;
            }

            .monthProgressBarContainer {
                background-color: #d5d5d5;
                border-radius: 10px;
                position: relative;

                .layoutS &, .layoutXS & {
                    grid-column: span 3;
                }

                .monthProgressText {
                    position: relative;
                    text-align: center;
                }

                .monthProgressBar {
                    background-color: #3dbdf4;
                    border-radius: 10px;
                    height: 100%;
                    left: 0px;
                    min-width: 18px;
                    position: absolute;
                    top: 0px;
                    transition: width 0.5s;
                    width: 0%;
                }
            }
        }
    }

    .tableCell.hasVenmo {
        cursor: pointer;
        position: relative;
        overflow: hidden;

        &:after {
            background-color: #0096FF;
            content: '';
            position: absolute;
            top: -5px;
            right: -5px;
            transform: rotate(45deg);
            height: 10px;
            width: 10px;
        }
    }

    .venmoDetailsDiv {
        background-color: #0096FF;
        border-radius: 0px 10px 10px 10px;
        color: #fff;
        padding: 5px 10px;
        position: absolute;
        // height: 100px;
        // width: 100px;
        white-space: pre;
    }

    .purchasesTableContainer {
        display: grid;
        // grid-template-columns: 65% 5px calc(35% - 5px);
        // grid-template-columns: calc(100% - (861px + 5px)) 5px 861px;
        // grid-gap: 5px;
        margin-top: 10px;

        @media screen and (max-width: 1635px) {
            grid-template-columns: 709px 5px calc(100% - (709px + 5px));
        }
        @media screen and (min-width: 1635px) {
            grid-template-columns: calc(100% - (861px + 5px)) 5px 861px;
        }

        &.smallLayout {
            display: block;
            margin-top: 0px;
        }

        .purchaseTableDragHandle {
            cursor: ew-resize;
        }
    }

    .purchasesTableContainer, .creditCardsTableContainer {
        .tableRow.highlighted .tableCell {
            background-color: #96ceff;
            /* border-color: #676767 !important; */
        }
    }

    .monthInfoContainer {
        margin-bottom: 10px;

        .tableRow {
            grid-template-columns: 100px 120px;

            .tableCell {
                background-color: rgb(216, 210, 231);

                &:nth-child(1):not(.emptyCell):not(.headerCell) {
                    background-color: rgb(178, 167, 210);
                }
            }

            .headerCell {
                background-color: rgb(139, 125, 190);

                &:nth-child(1) {
                    grid-column: span 2;
                }
            }
        }
    }

    .cardMonthContainer {
        display: grid;
        grid-template-columns: 50% 50%;
        grid-gap: 1px;

        .layoutXS &, .layoutS &, .layoutM & {
            grid-template-columns: auto;
        }

        .cardsInfoContainer, .monthInfoContainer {
            margin-bottom: 23px;
        }

        .cardsInfoContainer {
            .tableRow {
                grid-template-columns: auto 130px 130px 30px;

                .tableCell {
                    background-color: rgba(253, 242, 208, 1);

                    .darkMode & {
                        background-color: rgba(0, 60, 0, 0.4);
                    }
                }

                .headerCell {
                    background-color: rgb(249, 218, 120);

                    .darkMode & {
                        background-color: rgba(0, 120, 0, 0.4);
                    }
                }
            }
        }

        .category1TotalsContainer, .category2TotalsContainer {
            margin-bottom: 23px;

            .tableRow {
                grid-template-columns: auto 100px 80px;
            }
        }

        .category1TotalsContainer {
            .tableRow {
                .tableCell {
                    background-color: rgba(204, 217, 245, 1);
                }

                .headerCell {
                    background-color: rgb(120, 157, 229);
                }
            }
        }

        .category2TotalsContainer {
            .tableRow {
                .tableCell {
                    background-color: rgba(237, 205, 205, 1);
                }

                .headerCell {
                    background-color: rgb(209, 109, 106);
                }
            }
        }
    }
}