.home {
    background-color: #eaeaea;
    color: #000;
    display: grid;
    // grid-gap: 0px 20px;
    grid-template-columns: auto;
    grid-template-rows: calc(100% - 40px);
    height: 100%;
    padding-top:40px;
    position: fixed;
    width: 100%;

    .homeBoxShadow {
        box-shadow: 0px 0px 6px rgb(0 0 0 / 20%);
    }

    .myTableSectionHeader {
        font-size: 16px;
        margin: 15px 0px 10px;
    }

    .myTableContainer {
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 10px;
        padding: 14px 15px;
    }

    .linkLike {
        color: #005eff;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    .homeContentContainer {
        display: grid;
        grid-template-rows: 1fr;

        &.logsOpen:not(.logsFullScreen) {
            grid-template-rows: 1fr 1fr;
        }

        .homeContent {
            overflow: auto;
            padding: 10px 15px;

            .contentHeader {
                font-size: 20px;
            }
        }
    }
}