.newPurchaseContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    grid-gap: 15px;

    .layoutXS &, .layoutS & {
        grid-gap: 0px;
        grid-template-columns: 1fr;
        overflow: auto;
    }

    & > div {
        position: relative;

        .recurringDatesContainer {
            display: grid;
            grid-template-columns: auto auto;
        }

        .buttonContainer {
            bottom: 20px;
            position: absolute;
            width: 100%;
        }
    }
}